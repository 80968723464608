import React from "react"
import Container from "../components/container"
import BlueLink from "../components/buttons/bluelink"
import Row from "../components/row"
import PageLayout from "../layouts/pagelayout"

const NotFoundPage = () => (
  <PageLayout
    title="404: Not found"
    path={"/404"}
    crumbs={[["Page Not Found", "/404"]]}
    index={false}
  >
    <Container className="mt-16 min-h-screen">
      <Row isCentered={true}>
        <h1>The page you’re looking for can’t be found.</h1>
      </Row>
      <Row isCentered={true} className="mt-16">
        <BlueLink to="/sitemap">See our site map</BlueLink>
      </Row>
    </Container>
  </PageLayout>
)

export default NotFoundPage
